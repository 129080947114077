<template>
  <div class="px-5">
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{'mt-5': +i !== 0}"
      :ref="`ready_${currentDetail.id}`"
      :id="`ready_${currentDetail.id}`"
           class="navy-blue-border-1px border-15px"
      >
      <div class="d-flex navy-blue-border-bottom-1px px-2 py-2">
          <span class="h6 text-sm mr-2">{{$t('general.created_at')}}:</span><span class="text-sm">{{currentDetail.created_at?moment(currentDetail.created_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
          
          <span class="mx-1 h25"> / </span>
          <span class="h6 text-sm mr-2">
            {{$t('general.updated_date')}}:</span><span class="text-sm"> {{currentDetail.updated_at?moment(currentDetail.updated_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
          
      </div>
        <div class="d-flex navy-blue-border-bottom-1px">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.processes') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.dispatch_location') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Order', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_yarn_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :model.sync="currentDetail.fabric_process_ids"
                :name="`order_fabric_ready_made_yarn_type_${index}`"
                :options="fabricProcessOptions"
                :required="false"
                :showInvalid="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center">
            <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.dispatch_location_ids"
                :name="`order_fabric_ready_made_yarn_type_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
        </div>

        <date-detail-table-row
            :model="currentDetail"
            :type="1"
        ></date-detail-table-row>

        <div class="d-flex navy-blue-border-top-1px navy-blue-border-bottom-1px">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.color') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.fabric_type') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.color') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.color_arrow') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.weight_with_unit') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.width') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">

              <custom-multi-select
                  :item-per-row="1"
                  :max="1"
                  :model.sync="currentDetail.order_size_and_color_id"
                  :not-list="true"
                  :options="orderSizeAndColorOptions"
                  :placeholder="$t('general.please_select_option')"
                  :required="false"
                  :title="null"
                  name="orderSizeAndColorOptions"
                  width="300px"
                  @onSelect="orderSizeAndColorSave($event, index)"
              ></custom-multi-select>
            </div>
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input
                  v-model="currentDetail.fabric_type"
                  :class="{'is-invalid': !currentDetail.fabric_type}"
                  :name="`order_fabric_ready_made_color_${index}`"
                  :placeholder="$t('order.please_enter_fabric_type')"
                  :required="false"
                  :title="null"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input
                  v-model="currentDetail.color"
                  :disabled="true"
                  :class="{'is-invalid': !currentDetail.color}"
                  :name="`order_fabric_ready_made_color_${index}`"
                  :placeholder="$t('order.please_enter_color')"
                  :required="false"
                  :title="null"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input
                  v-model="currentDetail.color_ok"
                  :class="{'is-invalid': !currentDetail.color_ok}"
                  :name="`order_fabric_ready_made_color_arrow_${index}`"
                  :placeholder="$t('order.please_enter_color_arrow')"
                  :required="false"
                  :title="null"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input
                  v-model="currentDetail.weight"
                  :class="{'is-invalid': !currentDetail.weight}"
                  :name="`order_fabric_ready_made_weight_${index}`"
                  :placeholder="$t('order.please_enter_weight')"
                  :required="false"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="flex-1 py-2 text-center">
            <div class="px-2">
              <input
                  v-model="currentDetail.width"
                  :class="{'is-invalid': !+currentDetail.width}"
                  :name="`order_fabric_ready_made_width_${index}`"
                  :placeholder="$t('order.please_enter_width')"
                  :required="false"
                  :title="null"
                  class="form-control form-control-sm"
                  type="number"
              >
            </div>
          </div>
        </div>

        <div class="d-flex navy-blue-border-top-1px navy-blue-border-bottom-1px">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.quantity') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.unit') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.tolerance_quantity') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.total_quantity') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.incoming_quantity') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.remaining_quantity') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input
                  v-model="currentDetail.amount"
                  :class="{'is-invalid': !+currentDetail.amount}"
                  :name="`order_fabric_ready_made_amount_${index}`"
                  :placeholder="$t('order.please_enter_quantity')"
                  :required="false"
                  :title="null"
                  class="form-control form-control-sm"
                  type="number"
              >
            </div>
          </div>
          <div
              class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.amount_unit_id"
                :name="`amount_unit_id_${index}`"
                :not-list="true"
                :options="amountUnitOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input
                  v-model="currentDetail.tolerance_amount"
                  :class="{'is-invalid': !+currentDetail.tolerance_amount || +currentDetail.tolerance_amount > maxTolerance || +currentDetail.tolerance_amount < maxTolerance}"
                  :max="maxTolerance"
                  :disabled="true"
                  :name="`order_fabric_ready_made_tolerance_amount_${index}`"
                  :placeholder="$t('order.please_enter_tolerance_amount')"
                  :required="false"
                  :step="1"
                  :title="null"
                  class="form-control form-control-sm"
                  type="number"
              >
            </div>
          </div>

          <div class="flex-1 py-2 text-center navy-blue-border-right-1px d-flex justify-content-center items-center">
            {{ totalAmount(index) | amountFormat}}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px d-flex justify-content-center items-center">{{ currentDetail.incoming_amount | amountFormat }}</div>
          <div class="flex-1 py-2 text-center d-flex justify-content-center items-center"> {{ currentDetail.remaining_amount | amountFormat  }}</div>
        </div>

        <test-method-row
            :product-processes.sync="modelProxy[index].product_processes"
            :selected-test-methods.sync="modelProxy[index].selectedProductProcesses"
            :test-method-options="productProcesses"
        ></test-method-row>

        <currency-table-row
            :currency-options="currencyOptions"
            :currency-options-raw="currencyOptionsRaw"
            :currency-rate-options="currencyRateOptions"
            :expiration-type-options="expirationTypeOptions"
            :model="currentDetail"
            :v-a-t-rate-options="VATRateOptions"
        ></currency-table-row>

        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 px-2 py-3">
            <textarea-input
                :is-valid="!!currentDetail.description"
                :model.sync="currentDetail.description"
                :name="`order_fabric_ready_made_description_${index}`"
                :required="false"
                :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1"
            class="cursor-pointer"
            @click="addNewDetail"
            v-html="getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })"
      ></span>

      <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :send-mail-url="sendMailUrl(index)"
          :model="currentDetail"
          :order-id="orderId"
          name="yarn-request"
          @addNewDetail="addNewDetail"
          @onCancelInstruction="onCancelInstruction(currentDetail.id, index)"
          @submit="submitFabricReadyMadeRequest (index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import TestMethodRow from "@/view/pages/order/layout/TestMethodRow";
import CurrencyTableRow from "@/view/pages/order/layout/CurrencyTableRow";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import DateDetailTableRow from "@/view/pages/order/layout/DateDetailTableRow";
import BottomButtons from "@/view/pages/order/layout/BottomButtons";
import moment from "moment";
import {LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  GET_ITEM_DETAIL_BY_ID,
  UPDATE_ITEM_BY_ID
} from "@/core/services/store/REST.module";

export default {
  name: "OrderFabricReadyMadeTypeTable",
  components: {
    BottomButtons, DateDetailTableRow, TextareaInput, CurrencyTableRow, TestMethodRow, DatePickerInput, CustomMultiSelect
  },
  props: {
    model: {
      required: true,
    },
    totalInstructions:{
      required:false,
    },
    maxPrice:{
      required: false,
    },
    maxPriceUnit:{
      required:false,
    },
    amountUnitOptions: {
      required: true
    },
    defaultModel: {
      required: true,
    },
    orderId: {
      required: true,
      default: null
    },
    order: {
      required: true,
      default: null
    },
    orderFabricId: {
      required: true,
    },
    maxTolerance: {
      required: true,
      default: 0,
    },
    currentInstructionTypeId: {
      required: true,
    },
    fabricProcessOptions: {
      required: true,
    },
    currencyOptions: {
      required: true
    },
    currencyOptionsRaw: {
      required: true
    },
    VATRateOptions: {
      required: true,
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricYarnTypesOptions: {
      required: true,
    },
    testMethodOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: false,
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    productProcesses: {
      required: true,
    },
    orderFabricOptions: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    netIncomingFabricAmount: {
      required: true,
    },
    orderSizeAndColorOptions: {
      required: true,
    },
    orderSizeAndColorNameOptions: {
      required: true,
    }
  },
  data() {
    return {
      is_submitted: false,
    }
  },
  methods: {
    maxPriceCheck(index){
      let modelTotal = 0;
      let fabricCurrencyUnit=this.maxPriceUnit;
      let allCurrencyRate=this.currencyRateOptions;
      let orderFabric = _.find(this.order.order_fabrics,{'id':+this.orderFabricId});
      let modelItem = this.model[index];

      let modelExchanegeRate=1;
      let modelCurrencyUnit=null;
      if(modelItem.currency_unit){
        modelCurrencyUnit=modelItem.currency_unit.code;
      }else{
        modelCurrencyUnit=this.currencyOptions[modelItem.currency_unit_id];
      }
      
      let findCurrencyForFabric=_.find(allCurrencyRate,{"code":fabricCurrencyUnit});
      let findCurrencyForModel=_.find(allCurrencyRate,{"code":modelCurrencyUnit});

      if((modelCurrencyUnit=="TL"||modelCurrencyUnit=="TRY")&&(fabricCurrencyUnit=="TRY"||fabricCurrencyUnit=="TL")){
        modelExchanegeRate=findCurrencyForFabric.rate;
        modelTotal+=Number(modelItem.total_price)/modelExchanegeRate;

      }else if(fabricCurrencyUnit==modelCurrencyUnit){
        modelTotal+=Number(modelItem.total_price);

      }else{

        modelExchanegeRate=findCurrencyForModel.rate/orderFabric.exchange_rate;
        modelTotal+=Number(modelItem.total_price)*modelExchanegeRate;

      }


      let modelPlusTotalAllInstructions=this.totalInstructions;

      if(modelPlusTotalAllInstructions>this.maxPrice){
        return false;
      }
      return true;
    },
    orderSizeAndColorSave(selectedValue, index) {
      let model = this.modelProxy[index];
      let value = Number(selectedValue);
      let newItem = this.order.order_fabric_requirements.find(x => parseInt(x.order_fabric_id) === parseInt(model.order_fabric_id) && parseInt(x.order_size_and_color_id) === parseInt(model.order_size_and_color_id));
      model.amount = newItem.amount;
      if (this.netIncomingFabricAmount) {
        model.amount -= Number(this.netIncomingFabricAmount.find(item => +item.id === value).amount);
      }

      model.color = this.orderSizeAndColorNameOptions[value];
    },
    onCancelInstruction(itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/order/${this.orderId}/ready-made-request/${itemId}`
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            self.modelProxy = [self.defaultModel];
          }
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.id = null;

      this.sweetAlertLoading();
      this.$emit("getIncomingFabricAmount");
      setTimeout(() => {
        this.modelProxy.push(copy);
        this.sweetAlertClose();
      }, 1000);
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];

      item.dispatch_locations = item.dispatch_location_ids.map(location => {
        return {
          id: location,
        }
      });

      item.fabric_processes = item.fabric_process_ids.map(process => {
        return {
          id: process,
        };
      });

      item.order_fabric_id = self.orderFabricId;
      item.raw_flow_date = moment(item.raw_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.raw_end_date = moment(item.raw_end_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_flow_date = moment(item.painted_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_end_date = moment(item.painted_end_date).format(LARAVEL_DATE_TIME_FORMAT);

      items.push(item);

      return items;
    },
    submitFabricReadyMadeRequest(index) {
      let maxControl = this.maxPriceCheck(index);
      if(maxControl==false){
        this.sweetAlertError(this.$t('order.instruction_max_error'));
        return false;
      }
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/order/${self.orderId}/ready-made-request`,
        contents: {
          order_ready_made_request: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          detail.order_fabric_excel_link = responseData.order_fabric_excel_link
          detail.remaining_amount = responseData.remaining_amount

          self.modelProxy[index] = detail;
          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      });
    }
  },
  computed: {
    moment: () => moment,
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    },
    totalAmount() {
      return function (index) {
        this.modelProxy[index].total_amount = this.modelProxy[index].amount * (1 + (+this.modelProxy[index].tolerance_amount / 100))
        return (+this.modelProxy[index].total_amount);
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;
        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.supplier_company_id) return false;
          if (!+current.order_fabric_id) return false;
          if (!current.dispatch_location_ids.length) return false;
          if (current.fabric_type === null || current.fabric_type === '') return false;
          if (current.color === null || current.color === '') return false;
          if (current.color_ok === null || current.color_ok === '') return false;
          if (current.weight === null || current.weight === '') return false;
          if (!+current.width) return false;
          if (!+current.amount) return false;
          if (current.selectedProductProcesses.length === 0) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.order_size_and_color_id) return false;
          if (!+current.unit_price) return false;
          if (!+current.vat_rate_id) return false;
          if (!current.description) return false;
          return true;
        }

        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/order/${this.orderId}/ready-made-request/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  },
  mounted() {
    this.modelProxy.forEach(model => {
      if (!+model.tolerance_amount) {
        model.fabric_supply_type_id = 5;
        model.tolerance_amount = this.maxTolerance;
        model.order_fabric_id = this.orderFabricId;
        let newId = model.order_fabric_id;
        let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(newId));

        if (!model.weight) {
          model.weight = fabricItem.weight;
        }

        if (!model.width) {
          model.width = fabricItem.width;
        }
      }
    })
    if(this.$route.query.qr_type=="order-ready-request"){
      setTimeout(() => {
        let instructionsId = this.$route.query.data.id
        let element = this.$refs["ready_"+instructionsId][0]
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }, 2000);
    }
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        if (newValue) {
          newValue.forEach(model => {
            model.fabric_supply_type_id = 5;
            model.tolerance_amount = this.maxTolerance;
            model.order_fabric_id = this.orderFabricId;
            let newId = this.orderFabricId;
            let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(newId));
            if (!model.width) {
              model.width = fabricItem.width;
            }
            if (!model.weight) {
              model.weight = fabricItem.weight;
            }
          })
        }
      },
      deep: true
    },
    orderFabricId(value) {
      let self = this;
      this.modelProxy.forEach(model => {
        model.order_fabric_id = value;

        let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(value));

        if (!model.width) {
          model.width = fabricItem.width;
        }

        if (!model.weight) {
          model.weight = fabricItem.weight;
        }

        let newItem = this.order.order_fabric_requirements.find(x => parseInt(x.order_fabric_id) === parseInt(model.order_fabric_id) && parseInt(x.order_size_and_color_id) === parseInt(model.order_size_and_color_id));
        if (newItem) {
          model.amount = newItem.amount;
          if (self.netIncomingFabricAmount) {
            model.amount -= Number(self.netIncomingFabricAmount.find(item => +item.id === +model.order_size_and_color_id).amount);
          }
        }
      })
    },
    maxTolerance(value) {
      this.modelProxy.forEach(model => {
        model.tolerance_amount = value;
      })
    }
  }
}
</script>

<style scoped>

</style>
