<template>
  <div class="row d-flex w-100 mx-3 d-flex justify-content-left" v-if="orderReportData">
    <div class="card shadow-lg rounded w-100 my-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h4>{{ $t("order.cut_report") }}</h4>
        <i class="fas fa-times ml-auto float-right" @click="closeCutReport"></i>
      </div>
      <div class="card-body">
        <div
          class="row border py-2 report-area"
          v-for="(orderReport, key, index) in orderReportData.data"
          :key="orderReport.supplier_company_id"
        >
          <b-collapse id="cut_form" class="pt-1" visible>
            <div class="header-area d-flex w-100" v-b-toggle="`cut_form_${key + 1}`">
              <div class="col-2">
                <span>
                  <b style="font-weight: 600; font-size: 14px"
                    >{{ $t("general.customer") }}: </b
                  >{{ order.company.name }}</span
                >
              </div>
              <div class="col-2">
                <span>
                  <b style="font-weight: 600; font-size: 14px"
                    >{{ $t("general.order_number") }}: </b
                  >{{ order.order_number }}</span
                >
              </div>
              <div class="col-4">
                <span>
                  <b style="font-weight: 600; font-size: 14px"
                    >{{ $t("general.company") }}: </b
                  >{{ orderReport.supplier_company_name }}</span
                >
              </div>
              <div class="col-3">
                <span>
                  <b style="font-weight: 600; font-size: 14px"
                    >{{ $t("report.piece") }}: </b
                  >{{ sumExpenseDetailTarget(orderReport.order_size_and_colors) }}</span
                >
              </div>
              <div class="col-1 down-button-area d-flex justify-content-end">
                <i class="fas fa-chevron-down p-2"></i>
              </div>
            </div>
            <b-collapse :id="`cut_form_${key + 1}`" class="mb-5">
              <div class="col-12 border-top border m-0 p-0 mt-3">
                <table class="table table-sm main-table">
                  <thead>
                    <th class="blue-th">{{ $t("report.color") }}</th>
                    <th class="blue-th">{{ $t("report.size") }}</th>
                    <th class="blue-th">{{ $t("report.quantities") }}</th>
                    <th class="blue-th">{{ $t("report.sent_fabric_kg") }}</th>
                    <th class="blue-th">{{ $t("report.shipping_date") }}</th>
                    <th class="blue-th">{{ $t("report.manufacturer") }}</th>
                    <th class="blue-th">{{ $t("report.theoretical_gram") }}</th>
                    <th>{{ $t("report.theoretical_cutting_qty") }}</th>
                    <th class="reel-amount-td">{{ $t("report.actual_cutting_qty") }}</th>
                    <th>{{ $t("report.actual_product_qty") }}</th>
                    <th>{{ $t("report.total_loss") }}</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="orderSizeAndColor in orderReport.order_size_and_colors"
                      :key="orderSizeAndColor.order_size_and_color_id"
                    >
                      <td rowspan="1" style="vertical-align: inherit">
                        {{ orderSizeAndColor.order_size_and_color_name }}
                      </td>
                      <td>
                        <tbody class="inline-color-table-area">
                          <tr
                            v-for="bodySizes in JSON.parse(
                              orderSizeAndColor.order_size_and_color_sizes
                            )"
                            :key="bodySizes.id"
                          >
                            <td>
                              <b>{{ bodySizes.name }}</b>
                            </td>
                          </tr>
                        </tbody>
                      </td>
                      <td>
                        <tbody class="inline-color-table-area">
                          <tr
                            v-for="bodySizes in JSON.parse(
                              orderSizeAndColor.order_size_and_color_sizes
                            )"
                            :key="bodySizes.id"
                          >
                            <td>
                              <b>{{ bodySizes.amount }}</b>
                            </td>
                          </tr>
                        </tbody>
                      </td>
                      <td rowspan="1" style="vertical-align: inherit">
                        {{ orderSizeAndColor.waybill_transaction_amount }}
                      </td>
                      <td rowspan="1">
                        {{
                          moment(
                            orderSizeAndColor.first_waybill_date,
                            "YYYY-MM-DD"
                          ).format("DD.MM.YYYY")
                        }}
                      </td>
                      <td rowspan="1">
                        {{ orderSizeAndColor.supplier_company_name }}
                      </td>

                      <td rowspan="1">
                        <!-- Teorik gram Sipariş adedi / Çıkış yapılan kumaş -->
                        {{
                          (
                            Number(orderSizeAndColor.waybill_transaction_amount) /
                            Number(order.amount)
                          ).toFixed(3)
                        }}
                      </td>
                      <td rowspan="1">
                        {{
                          Number(orderSizeAndColor.waybill_transaction_amount) /
                          (Number(orderSizeAndColor.waybill_transaction_amount) /
                            Number(order.amount))
                        }}
                      </td>
                      <td rowspan="1">
                        <!-- reel kesim adedi -->
                        {{ orderSizeAndColor.order_expense_detail_targets }}
                      </td>
                      <td rowspan="1">
                        {{
                          (
                            Number(orderSizeAndColor.waybill_transaction_amount) /
                            Number(orderSizeAndColor.order_expense_detail_targets)
                          ).toFixed(3)
                        }}
                      </td>
                      <td>
                        <b>{{ loss(orderSizeAndColor) }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-collapse>
          </b-collapse>
        </div>
        <div class="general-area d-flex w-100 mt-5" v-b-toggle="`cut_form_${key + 1}`">
          <div class="col-2">
            <b>
              <span style="font-weight: 600; font-size: 14px">{{
                $t("order.general_total")
              }}</span>
            </b>
          </div>
          <div class="col-2">
            <span>
              <b style="font-weight: 600; font-size: 14px"
                >{{ $t("report.quantities") }}: </b
              >{{ orderReportData.totals.body_size_total }}</span
            >
          </div>
          <div class="col-3">
            <span>
              <b style="font-weight: 600; font-size: 14px"
                >{{ $t("report.sent_fabric_kg") }}: </b
              >{{ orderReportData.totals.waybill_total_amount }}</span
            >
          </div>
          <div class="col-3">
            <span>
              <b style="font-weight: 600; font-size: 14px"
                >{{ $t("report.theoretical_gram") }}: </b
              >{{ orderReportData.totals.theoretical_total.toFixed(4) }}</span
            >
          </div>
          <div class="col-2">
            <span>
              <b style="font-weight: 600; font-size: 14px"
                >{{ $t("report.actual_cutting_qty") }}: </b
              >{{ orderReportData.totals.reel_cut_total }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

// COMPONENTS
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
  LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "loadingDeadlineReport",
  props: ["order", "orderId", "orderReportData"],
  components: {
    SelectFilter,
    InputFilter,
    DateFilter,
    CustomMultiSelect,
  },
  computed: {
    moment: () => moment,
  },
  data() {
    return {};
  },
  methods: {
    setup() {
      let self = this,
        promises = [];
      //0
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );

      Promise.all(promises).then((results) => {});
    },
    sumExpenseDetailTarget(orderSizeAndColor){
      console.log(orderSizeAndColor);
      return _.sumBy(orderSizeAndColor, function(o) { return Number(o.order_expense_detail_targets); });
    },
    countSizeAndColor(sizeAndColor) {
      let jsonParse = JSON.parse(sizeAndColor);
      let size = Object.keys(jsonParse).map((key) => [key, jsonParse[key]]);
      return size.length;
    },
    closeCutReport() {
      this.$emit("closeCutReport", true);
    },
    loss(payload) {
      let orderSizeAndColor = payload;
      let tehoric_gr =
        Number(orderSizeAndColor.waybill_transaction_amount) / Number(this.order.amount);

      let real_gr =
        Number(orderSizeAndColor.waybill_transaction_amount) /
        Number(orderSizeAndColor.order_expense_detail_targets);
      let total_loss =
        (real_gr - tehoric_gr) * orderSizeAndColor.order_expense_detail_targets;
      return total_loss.toFixed(3);
    },
  },
  created() {},
  mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.fa-times {
  color: rgb(184, 81, 81);
  background-color: rgba(222, 222, 222, 0.441);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: rgb(247, 3, 3);
    background-color: rgba(190, 190, 190, 0.25);
    padding: 5px;
    border-radius: 5px;
  }
}

.inline-color-table-area {
  border-radius: 5px !important;
  tr {
    border-right: 0px;
    td {
      border-right: 0px !important;
    }

    &:first-child {
      td {
        &:first-child {
          border-top: 0px;
        }
        &:nth-child(2) {
          &:first-child {
            border-top: 0px;
          }
        }
      }
    }
  }
}
.main-table {
  border-radius: 5px !important;
  thead {
    th {
      border-right: 1px solid #cecece;
      &:last-child {
        border-right: none;
      }
    }
  }
  tbody {
    tr {
      td {
        border-right: 1px solid #cecece;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
.report-area {
  border-radius: 5px !important;
  border: 0px !important;
  margin-top: 15px;
  .border-top {
    border-radius: 8px !important;
  }
  div {
    border-right: 1px solid #cecece;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
  }
  &:first-child {
    margin-top: 0px;
  }
}

.reel-amount-td {
  background-color: #452985;
  color: white;
}
.blue-th {
  background-color: #4f77b7;
  color: white;
  &:first-child {
    border-top-left-radius: 8px;
  }
}

.header-area {
  border-radius: 5px;
  background-color: #f1f1f1;
  div {
    border-radius: 5px !important;
  }
}

.collapse {
  border: 0px !important;
}
#cut_form {
  width: 100%;
}

.down-button-area {
  background-color: white;
  border: 0px !important;
  padding-left: 5px;
}

.general-area {
}
</style>
