<template>
  <div>
    <div v-if="fabricSourcingType != null" class="d-flex w-100 justify-content-start items-baseline mt-5 mb-2 ">
      <span v-html="getIconByKey('icons.sample_instruction.fabric_supply_management', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'})"></span>
      <div class="mb-0">
          <span class="text-xl font-weight-bolder bottom-border-1px-navy">{{
              $t('sample.fabric_supply_method').toUpperCase()
            }}
          </span> :
      </div>
      <div class="text-xl font-weight-boldest text-primary w-200px">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="fabricSourcingType"
            :not-list="true"
            :options="sourcingTypeOptions"
            :placeholder="$t('general.please_select_option')"
            :required="false"
            :title="null"
            name="sourcingTypeOptions"
            width="300px"
            @onSelect="getRequests"
        ></custom-multi-select>
      </div>
      <div v-if="fabricProcessingType !== null && +fabricSourcingType === 1"
           class="text-xl font-weight-boldest text-primary w-200px pb-2">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="fabricProcessingType"
            :not-list="true"
            :options="fabricProcessingTypes"
            :placeholder="$t('general.please_select_option')"
            :required="false"
            :title="null"
            name="sourcingTypeOptions"
            width="300px"
            @onSelect="getRequests"
        ></custom-multi-select>
      </div>

      <div v-if="fabricProcessingType !== null"
           class="text-xl font-weight-boldest text-primary w-200px pb-2">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="orderFabricId"
            :not-list="true"
            :options="orderFabricOptions"
            :placeholder="$t('general.please_select_option')"
            :required="false"
            :title="null"
            name="orderFabricOptions"
            width="300px"
            @onSelect="getRequests"
        ></custom-multi-select>
      </div>

      <div
          v-if="[2, 4].includes(+fabricProcessingType) && selectedPrintTypeId && +fabricSourcingType === 1"
          class="text-xl font-weight-boldest text-primary w-200px pb-2">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="selectedPrintTypeId"
            :not-list="true"
            :options="options.fabricPrintTypeOptions"
            :placeholder="$t('general.please_select_option')"
            :required="false"
            :title="null"
            name="sourcingTypeOptions"
            width="300px"
        ></custom-multi-select>
      </div>

    </div>
    <template v-if="fabricSourcingType === null">
      <div class="d-flex justify-content-center items-center">
                  <span v-html="getIconByKey('icons.sample_instruction.question', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'
                    })"></span>
        <p class="mb-0 font-weight-bolder text-navy-blue text-xl">
          {{ $t('sample.how_to_make_fabric_question') }}
        </p>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <button-with-icon
            :active="true"
            :disabled="fabricSourcingType !== 1"
            :icon-name="null"
            :text="$t('sample.outsourcing')"
            class="text-primary font-weight-boldest w-250px mx-2"
            size="lg"
            @onClick="fabricSourcingType = 1"
        ></button-with-icon>
        <button-with-icon
            :active="true"
            :disabled="fabricSourcingType !== 2"
            :icon-name="null"
            :text="$t('sample.buy_ready_made')"
            class="text-primary font-weight-boldest w-250px mx-2"
            size="lg"
            @onClick="fabricSourcingType = 2"
        ></button-with-icon>
      </div>
    </template>

    <div v-if="+fabricSourcingType === 1 && fabricProcessingType === null" class="mt-5">
      <div class="w-100 row d-flex justify-content-center my-2">
        <div v-for="(fbType, index) in fabricProcessingTypes"
             class="col-xl-3 col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
          <button-with-icon
              :active="true"
              :disabled="+fabricProcessingType !== +index"
              :icon-name="null"
              :text="fbType"
              class="text-primary font-weight-boldest mx-2 w-250px"
              size="md"
              @onClick="fabricProcessingType = +index"
          ></button-with-icon>
        </div>
      </div>
    </div>

    <template v-if="fabricSourcingType != null && +fabricSourcingType === 1 && orderFabricId!=null">
      <div
      style="border-radius:15px"
      class="table-responsive navy-blue-border-left-1px navy-blue-border-right-1px navy-blue-border-bottom-1px navy-blue-border-top-1px">
        <table 
        sty
        class="table table-sm">
          <thead>
            <tr>
              <th class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                <div class="w-100 d-flex justify-content-center items-center">
                  <span class="d-inline-block px-5">{{ $t("order.max") }}</span>
                </div>
              </th>
              <th v-for="(value, key) in allInstructionTotalfabricSourcingTypeOne.allInstructionTotal"
                  :key="'total' + key"
                  class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                <div class="w-100 d-flex justify-content-center items-center">
                  <span class="d-inline-block px-5">{{ $t(value.title) }}</span>
                </div>
              </th>
              <th class="text-center navy-blue-border-bottom-1px ">
                <div class="w-100 d-flex justify-content-center items-center">
                  <span class="d-inline-block px-5">{{ $t("order.total") }}</span>
                </div>
              </th>
            </tr>
            </thead>
             <tbody>
              <tr class="h-100">
                <td class="navy-blue-border-right-1px">
                  <div class="d-flex justify-content-center">
                    <span class="d-inline-block px-5">{{ allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' '+ allInstructionTotalfabricSourcingTypeOne.fabricCurrency }}</span>
                  </div>
                </td>
                <td v-for="(value, key) in allInstructionTotalfabricSourcingTypeOne.allInstructionTotal"
                    :key="'total' + key"
                    class="navy-blue-border-right-1px">
                  <div class="d-flex justify-content-center">
                    <span class="d-inline-block px-5">{{ value.total.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' '+ allInstructionTotalfabricSourcingTypeOne.fabricCurrency }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-content-center">
                    <span class="d-inline-block px-5"
                    :style="[allInstructionTotalfabricSourcingTypeOne.totalInstruction>allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric ? {color:'red'} : {color:'green'}]"
                    >{{ allInstructionTotalfabricSourcingTypeOne.totalInstruction.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' '+ allInstructionTotalfabricSourcingTypeOne.fabricCurrency }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
    </template>
    <template v-if="fabricSourcingType != null && +fabricSourcingType === 2 && orderFabricId!=null">
      <div
      style="border-radius:15px"
      class="table-responsive navy-blue-border-left-1px navy-blue-border-right-1px navy-blue-border-bottom-1px navy-blue-border-top-1px">
        <table 
        sty
        class="table table-sm">
          <thead>
            <tr>
              <th class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                <div class="w-100 d-flex justify-content-center items-center">
                  <span class="d-inline-block px-5">{{ $t("order.max") }}</span>
                </div>
              </th>
              <th v-for="(value, key) in allInstructionTotalfabricSourcingTypeTwo.allInstructionTotal"
                  :key="'total' + key"
                  class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                <div class="w-100 d-flex justify-content-center items-center">
                  <span class="d-inline-block px-5">{{ $t(value.title) }}</span>
                </div>
              </th>
              <th class="text-center navy-blue-border-bottom-1px ">
                <div class="w-100 d-flex justify-content-center items-center">
                  <span class="d-inline-block px-5">{{ $t("sample.total") }}</span>
                </div>
              </th>
            </tr>
            </thead>
             <tbody>
              <tr class="h-100">
                <td class="navy-blue-border-right-1px">
                  <div class="d-flex justify-content-center">
                    <span class="d-inline-block px-5">{{ allInstructionTotalfabricSourcingTypeTwo.maxToleranceFabric.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' '+ allInstructionTotalfabricSourcingTypeTwo.fabricCurrency }}</span>
                  </div>
                </td>
                <td v-for="(value, key) in allInstructionTotalfabricSourcingTypeTwo.allInstructionTotal"
                    :key="'total' + key"
                    class="navy-blue-border-right-1px">
                  <div class="d-flex justify-content-center">
                    <span class="d-inline-block px-5">{{ value.total.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' '+ allInstructionTotalfabricSourcingTypeTwo.fabricCurrency }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-content-center">
                    <span class="d-inline-block px-5"
                    :style="[allInstructionTotalfabricSourcingTypeTwo.totalInstruction>allInstructionTotalfabricSourcingTypeTwo.maxToleranceFabric ? {color:'red'} : {color:'green'}]"
                    >{{ allInstructionTotalfabricSourcingTypeTwo.totalInstruction.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' '+ allInstructionTotalfabricSourcingTypeTwo.fabricCurrency }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
    </template>

    <div v-if="+fabricSourcingType === 1 && orderFabricId != null" class="w-100 row mx-0 px-10 mb-5 items-baseline">
      <div v-for="(sourcingType, index) in outSourcingTypes" v-if="sourcingType.value.includes(+fabricProcessingType)">
        <span
            :class="{'bottom-border-2px-primary': selectedSubSourcingType === +sourcingType.id}"
            class="text-lg font-weight-bold px-3 pb-1 cursor-pointer"
            @click="changeSelectedSubSource(sourcingType)"
        >{{ $t(index).toUpperCase() }} </span>
      </div>
      <template
          v-if="additionDetailModel.length && additionDetailModel[0].fabric_addition_id !== null && fabricProcessingType !== null && +fabricSourcingType === 1 && orderFabricId != null">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="additionDetailModel[0].fabric_addition_id"
            :name="`fabric_name_addition_option`"
            :not-list="true"
            :options="options.fabricAdditionOptions"
            :required="false"
            :size="'sm'"
            :title="null"
            class="mb-0"
            width="200px"
            @save="onFabricAdditionValueChange"
        ></custom-multi-select>
      </template>
    </div>

   
    <div v-if="+fabricSourcingType === 2 && orderFabricId != null" class="w-100 row mx-0 px-10 mb-5 items-baseline">
      <div v-for="(readySourcingType, index) in readyOutSourcingTypes">
        <span
            :class="{'bottom-border-2px-primary': readySelectedSubSourcingType === +readySourcingType.id}"
            class="text-lg font-weight-bold px-3 pb-1 cursor-pointer"
            @click="readyChangeSelectedSubSource(readySourcingType)"
        >{{ $t(index).toUpperCase() }} </span>
      </div>
     
      <template
          v-if="readyAdditionDetailModel.length && readyAdditionDetailModel[0].fabric_addition_id !== null && fabricProcessingType !== null && +fabricSourcingType === 2 && orderFabricId != null">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="readyAdditionDetailModel[0].fabric_addition_id"
            :name="`fabric_name_addition_option`"
            :not-list="true"
            :options="options.fabricAdditionOptions"
            :required="false"
            :size="'sm'"
            :title="null"
            class="mb-0"
            width="200px"
            @save="onFabricAdditionValueChange"
        ></custom-multi-select>
      </template>
    </div>
    
    
    <template
        v-if="readyAdditionDetailModel==8 && !selectedPrintTypeId && orderFabricId != null">
      <div class="w-100 d-flex flex-column justify-content-center items-center">
        <span
            class="d-inline-block py-2 my-5 text-xl font-weight-bold">{{
            $t('order.please_select_print_type')
          }}</span>
        <div class="d-flex">
          <button-with-icon v-for="(fabricPrintType, key) in options.fabricPrintTypeOptions"
                            :key="key"
                            :active="true"
                            :icon-name="null"
                            :text="fabricPrintType"
                            class="mw-150px mx-1"
                            @onClick="changeFabricPrintTypeId(+key)"
          ></button-with-icon>
        </div>
      </div>
    </template>
    
    <template
        v-else-if="!([2, 4].includes(+fabricProcessingType) && !selectedPrintTypeId) && !isDownloading && orderFabricId != null">
        
      <template v-if="+fabricSourcingType === 1">
        <template v-if="+selectedSubSourcingType === 1">
          <OrderFabricYarnTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="yarnDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :fabric-yarn-type-options="options.fabricYarnTypeOptions"
              :max-tolerance.sync="maxToleranceAmount"
              :model.sync="yarnDetailModel"
              :net-incoming-fabric-amount="netIncomingFabricAmount"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :product-processes="options.productProcesses"
              :supplier-company-options="options.supplierCompanyOptions"
              :test-method-options="options.testMethodOptions"
              :v-a-t-rate-options="options.VATRateOptions"
              @getIncomingFabricAmount="getIncomingFabricAmount"
              :max-price="allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeOne.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeOne.totalInstruction"
          ></OrderFabricYarnTypeTable>
        </template>
        <template v-if="+selectedSubSourcingType === 2">
          <OrderFabricKnitTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="knitDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-knit-type-options="options.fabricKnitTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :fabric-type-options="options.fabricTypeOptions"
              :max-tolerance.sync="maxToleranceAmount"
              :model.sync="knitDetailModel"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-id.sync="orderSizeAndColorId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :press-type-id="selectedPrintTypeId"
              :press-type-tolerances="printTolerances"
              :product-processes="options.productProcesses"
              :supplier-company-options="options.supplierCompanyOptions"
              :tolerances="tolerances"
              :v-a-t-rate-options="options.VATRateOptions"
              :yarn-model="yarnDetailModel"
              :max-price="allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeOne.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeOne.totalInstruction"
          ></OrderFabricKnitTypeTable>
        </template>
        <template v-if="+selectedSubSourcingType === 3">
          <OrderFabricPaintTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="paintDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-process-options="options.fabricProcessTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :knitting-company-options="[]"
              :model.sync="paintDetailModel"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-id.sync="orderSizeAndColorId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :order-size-and-color-name-options="orderSizeAndColorNameOptions"
              :previous-model="[1, 2].includes(+fabricProcessingType) ? knitDetailModel : rawFabricDetailModel"
              :product-processes="options.productProcesses"
              :supplier-company-options="options.supplierCompanyOptions"
              :tolerances="tolerances"
              :v-a-t-rate-options="options.VATRateOptions"
              :yarn-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
              :max-price="allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeOne.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeOne.totalInstruction"
              
          ></OrderFabricPaintTypeTable>
        </template>
        <template v-if="+selectedSubSourcingType === 4">
          <OrderFabricRawFabricTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="rawFabricDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :fabric-type-options="options.fabricTypeOptions"
              :max-tolerance.sync="maxToleranceAmount"
              :model.sync="rawFabricDetailModel"
              :net-incoming-fabric-amount="netIncomingFabricAmount"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-id.sync="orderSizeAndColorId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :product-processes="options.productProcesses"
              :supplier-company-options="options.supplierCompanyOptions"
              :v-a-t-rate-options="options.VATRateOptions"
              @getIncomingFabricAmount="getIncomingFabricAmount"
              :max-price="allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeOne.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeOne.totalInstruction"
          ></OrderFabricRawFabricTypeTable>
        </template>
        <template v-if="+selectedSubSourcingType === 5">
          <OrderFabricPrintTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="printDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-addition-options="options.fabricAdditionOptions"
              :fabric-print-type-options="options.fabricPrintTypeOptions"
              :fabric-process-options="options.fabricProcessTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :model.sync="printDetailModel"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-id.sync="orderSizeAndColorId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :previous-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
              :product-processes="options.productProcesses"
              :selected-print-type-id="selectedPrintTypeId"
              :supplier-company-options="options.supplierCompanyOptions"
              :tolerances="printTolerances"
              :v-a-t-rate-options="options.VATRateOptions"
              :yarn-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
              :max-price="allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeOne.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeOne.totalInstruction"
          ></OrderFabricPrintTypeTable>
        </template>
        <template v-if="+selectedSubSourcingType === 6">
          <OrderFabricExtraProcessTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="additionDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-addition-options="options.fabricAdditionOptions"
              :fabric-process-options="options.fabricProcessTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :model.sync="additionDetailModel"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-id.sync="orderSizeAndColorId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :previous-model="[1,3].includes(+fabricProcessingType) ? paintDetailModel : printDetailModel"
              :product-processes="options.productProcesses"
              :supplier-company-options="options.supplierCompanyOptions"
              :tolerances="tolerances"
              :v-a-t-rate-options="options.VATRateOptions"
              :yarn-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
              :max-price="allInstructionTotalfabricSourcingTypeOne.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeOne.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeOne.totalInstruction"
          ></OrderFabricExtraProcessTypeTable>
        </template>
      </template>
      <template v-else>
        <template  v-if="readySelectedSubSourcingType==7">
          <OrderFabricReadyMadeTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="readyMadeDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-process-options="options.fabricProcessTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :fabric-yarn-types-options="options.fabricYarnTypeOptions"
              :max-tolerance.sync="maxToleranceAmount"
              :model.sync="readyMadeDetailModel"
              :net-incoming-fabric-amount="netIncomingFabricAmount"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-id.sync="orderSizeAndColorId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :order-size-and-color-name-options="orderSizeAndColorNameOptions"
              :product-processes="options.productProcesses"
              :supplier-company-options="options.supplierCompanyOptions"
              :test-method-options="options.testMethodOptions"
              :v-a-t-rate-options="options.VATRateOptions"
              @getIncomingFabricAmount="getIncomingFabricAmount"
              :max-price="allInstructionTotalfabricSourcingTypeTwo.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeTwo.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeTwo.totalInstruction"
          ></OrderFabricReadyMadeTypeTable>
        </template>
        <template  v-if="readySelectedSubSourcingType==8">
          <OrderFabricReadyExtraProcessTypeTable
              :amount-unit-options="options.unitOptions"
              :currency-options="options.currencyOptions"
              :currency-options-raw="options.currencyRawOptions"
              :currency-rate-options="options.currencyRateOptions"
              :current-instruction-type-id="currencyInstructionTypeId"
              :default-model="readyMadeDetailModelDefault"
              :did-change-processing-type="didChangeProcessingType"
              :dispatch-location-options="options.dispatchLocationOptions"
              :expiration-type-options="options.expirationTypeOptions"
              :fabric-addition-options="options.fabricAdditionOptions"
              :fabric-process-options="options.fabricProcessTypeOptions"
              :fabric-processing-type="fabricProcessingType"
              :model.sync="readyAdditionDetailModel"
              :order="order"
              :order-fabric-id.sync="orderFabricId"
              :order-fabric-options="order.order_fabrics"
              :order-id="orderId"
              :order-size-and-color-id.sync="orderSizeAndColorId"
              :order-size-and-color-options="orderSizeAndColorOptions"
              :previous-model="readyMadeDetailModel"
              :product-processes="options.productProcesses"
              :supplier-company-options="options.supplierCompanyOptions"
              :tolerances="tolerances"
              :v-a-t-rate-options="options.VATRateOptions"
              :yarn-model="readyMadeDetailModel"
              :max-price="allInstructionTotalfabricSourcingTypeTwo.maxToleranceFabric"
              :max-price-unit="allInstructionTotalfabricSourcingTypeTwo.fabricCurrency"
              :total-instructions="allInstructionTotalfabricSourcingTypeTwo.totalInstruction"
          ></OrderFabricReadyExtraProcessTypeTable>
        </template>
      </template>
      
    </template>
  </div>
</template>

<script>
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import OrderFabricYarnTypeTable from "@/view/pages/order/layout/OrderFabricYarnTypeTable";
import OrderFabricKnitTypeTable from "@/view/pages/order/layout/OrderFabricKnitTypeTable";
import moment from "moment";
import OrderFabricPaintTypeTable from "@/view/pages/order/layout/OrderFabricPaintTypeTable";
import OrderFabricExtraProcessTypeTable from "@/view/pages/order/layout/OrderFabricExtraProcessTypeTable";
import OrderFabricPrintTypeTable from "@/view/pages/order/layout/OrderFabricPrintTypeTable";
import OrderFabricRawFabricTypeTable from "@/view/pages/order/layout/OrderFabricRawFabricTypeTable";
import OrderFabricReadyMadeTypeTable from "@/view/pages/order/layout/OrderFabricReadyMadeTypeTable";
import OrderFabricReadyExtraProcessTypeTable from "@/view/pages/order/layout/OrderFabricReadyExtraProcessTypeTable";
import {GET_ITEM_DETAIL_BY_ID, GET_ITEMS} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import moduleUser, {GENERAL_SETTING, MODULE_NAME as MODULE_USER} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import _ from "lodash";

const _MODULE_USER = MODULE_USER;

export default {
  name: "OrderFabric",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  props: {
    options: {
      required: true,
      type: Object,
    },
    orderId: {
      required: true,
      default: null,
    },
    order: {
      required: true,
      default: null,
    }
  },
  components: {
    OrderFabricReadyMadeTypeTable,
    OrderFabricRawFabricTypeTable,
    OrderFabricPrintTypeTable,
    OrderFabricExtraProcessTypeTable,
    OrderFabricPaintTypeTable,
    OrderFabricKnitTypeTable,
    OrderFabricYarnTypeTable,
    OrderFabricReadyExtraProcessTypeTable,
    CustomMultiSelect,
    ButtonWithIcon,
  },
  data() {
    return {
      selectedPrintTypeId: null,
      orderFabricId: null,
      orderSizeAndColorId: null,
      netIncomingFabricAmount: 0,
      isDownloading: false,
      sourcingTypeOptions: {
        '1': this.capitalize(this.$t('sample.outsourcing')),
        '2': this.capitalize(this.$t('sample.buy_ready_made'))
      },

      fabricSourcingType: null,
      fabricProcessingType: null,
      selectedSubSourcingType: null,
      readySelectedSubSourcingType: null,

      fabricProcessingTypes: {
        '1': this.$t('sample.yarn_knitting_paint'),
        '2': this.$t('sample.yarn_knitting_print'),
        '3': this.$t('sample.raw_fabric_paint'),
        '4': this.$t('sample.raw_fabric_print'),
      },
      readyOutSourcingTypes: {
        'sample.ready_purchase': {
          id: 7
        },
        'sample.extra_processes': {
          id: 8
        },
      },
      outSourcingTypes: {
        'sample.yarn': {
          id: 1,
          value: [1, 2]
        },
        'sample.knitting': {
          id: 2,
          value: [1, 2]
        },
        'sample.raw_fabric': {
          id: 4,
          value: [3, 4]
        },
        'sample.paint': { // paint
          id: 3,
          value: [1, 3]
        },
        'sample.press': { // print
          id: 5,
          value: [2, 4]
        },
        'sample.extra_processes': {
          id: 6,
          value: [1, 2, 3, 4]
        },
      },
      readyMadeDetailModel: [{}],
      yarnDetailModel: [{}],
      knitDetailModel: [{}],
      paintDetailModel: [{}],
      additionDetailModel: [{}],
      printDetailModel: [{}],
      rawFabricDetailModel: [{}],
      readyAdditionDetailModel: [{}],
      readyAdditionDetailModelDefault: {
            id: null,
            order_id: this.orderId,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            fabric_addition_id: null,
            supplier_company_id: null,
            fabric_process_ids: [],
            dispatch_location_ids: [],
            painted_flow_date: moment(),
            painted_end_date: moment(),
            fabric_type: null,
            color: null,
            color_ok: null,
            weight: null,
            width: null,
            amount: 0,
            amount_unit_id: null,
            tolerance_amount: 0,
            total_amount: 0,
            remaining_amount: 0,
            exchange_rate: 1,
            total_price: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            unit_price: null,
            vat_rate_id: null,
            description: null,
          },
      readyMadeDetailModelDefault:
          {
            id: null,
            order_id: this.orderId,
            order_fabric_id: null,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            fabric_process_ids: [],
            dispatch_location_ids: [],
            raw_flow_date: moment(),
            raw_end_date: moment(),
            painted_flow_date: moment(),
            painted_end_date: moment(),
            fabric_type: null,
            color: null,
            color_ok: null,
            weight: null,
            width: null,
            amount: null,
            amount_unit_id: null,
            tolerance_amount: null,
            total_amount: null,
            incoming_amount: 0,
            remaining_amount: 0,
            exchange_rate: 1,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            unit_price: null,
            vat_rate_id: null,
            description: null,
          },
      yarnDetailModelDefault:
          {
            id: null,
            order_id: this.orderId,
            order_fabric_id: null,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            yarn_type: null,
            flow_date: moment(),
            end_date: moment(),
            color: null,
            amount: null,
            amount_unit_id: null,
            tolerance_amount: null,
            total_amount: null,
            currency_unit_id: null,
            exchange_rate: 1,
            expiry_id: null,
            total_price: null,
            description: null,
            selectedProductProcesses: [],
            product_processes: [],
            dispatch_location_ids: [],
            unit_price: null,
            vat_rate_id: null,
          },
      knitDetailModelDefault:
          {
            id: null,
            order_id: this.orderId,
            is_submitted: false,
            order_size_and_color_id: null,
            is_editing: false,
            supplier_company_id: null,
            fabric_type: null,
            fabric_knit_type_id: null,
            flow_date: moment(),
            end_date: moment(),
            dispatch_location_ids: [],
            fein_pus: null,
            color: null,
            amount: null,
            amount_unit_id: null,
            tolerance_amount: null,
            total_amount: null,
            exchange_rate: 1,
            incoming_amount: 0,
            remaining_amount: 0,
            total_price: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            unit_price: null,
            vat_rate_id: null,
            description: null,
          },
      paintDetailModelDefault:
          {
            id: null,
            order_id: this.orderId,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            knitter_company_id: null,
            fabric_process_ids: [],
            dispatch_location_ids: [],
            raw_flow_date: moment(),
            raw_end_date: moment(),
            painted_flow_date: moment(),
            painted_end_date: moment(),
            fabric_type: null,
            color: null,
            color_ok: null,
            weight: null,
            width: null,
            amount: null,
            amount_unit_id: null,
            tolerance_amount: null,
            total_amount: null,
            exchange_rate: 1,
            price: null,
            total_price: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            unit_price: null,
            vat_rate_id: null,
            description: null,

          },
      additionDetailModelDefault:
          {
            id: null,
            order_id: this.orderId,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            fabric_addition_id: null,
            supplier_company_id: null,
            fabric_process_ids: [],
            dispatch_location_ids: [],
            painted_flow_date: moment(),
            painted_end_date: moment(),
            fabric_type: null,
            color: null,
            color_ok: null,
            weight: null,
            width: null,
            amount: 0,
            amount_unit_id: null,
            tolerance_amount: 0,
            total_amount: 0,
            remaining_amount: 0,
            exchange_rate: 1,
            total_price: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            unit_price: null,
            vat_rate_id: null,
            description: null,
          },
      printDetailModelDefault:
          {
            id: null,
            order_id: this.orderId,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            press_type_id: null,
            supplier_company_id: null,
            fabric_process_ids: [],
            dispatch_location_ids: [],
            raw_flow_date: moment(),
            raw_end_date: moment(),
            painted_flow_date: moment(),
            painted_end_date: moment(),
            fabric_type: null,
            color: null,
            color_ok: null,
            weight: null,
            width: null,
            amount: null,
            amount_unit_id: null,
            tolerance_amount: null,
            total_amount: null,
            exchange_rate: 1,
            total_price: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            unit_price: null,
            vat_rate_id: null,
            description: null,
          },
      rawFabricDetailModelDefault:
          {
            id: null,
            order_id: this.orderId,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            fabric_type: null,
            knitting_type: null,
            flow_date: moment(),
            end_date: moment(),
            dispatch_location_ids: [],
            fein_pus: null,
            color: null,
            amount: null,
            amount_unit_id: null,
            tolerance_amount: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            total_price: null,
            exchange_rate: 1,
            unit_price: null,
            vat_rate_id: null,
            description: null,
          }
    }
  },
  computed: {
    allInstructionTotalfabricSourcingTypeTwo(){
      let allInstruction=[];
      let allInstructionKeys=[];
      let allInstructionTotal=[];
      let fabricCurrency=null;
      let maxToleranceFabric=0;
      let totalModels=0;
      let readyOutSourcingTypes=_.cloneDeep(this.readyOutSourcingTypes);
      Object.entries(readyOutSourcingTypes).forEach(([key, sourcingType]) => {
          allInstructionKeys.push(key);
      });

      let selectedOrderFabricId=+this.orderFabricId;
      let orderFabrics=_.cloneDeep(this.order.order_fabrics);
      let findOrderFabric=_.find(orderFabrics,{'id':selectedOrderFabricId})
      let fabricCurrencyUnit=null;
      let allCurrencyRate=this.options.currencyRateOptions;
      // maxsimum bu siparişe girilebilecek miktar bulunuyor
  
      if(findOrderFabric){
        let order_fabric_requirements=_.cloneDeep(this.order.order_fabric_requirements);
        let sumAmount=0;
        order_fabric_requirements.forEach((item)=>{
          if(item.order_fabric_id==findOrderFabric.id){
            sumAmount+=item.amount;
          }
        })

        let toleranceAmountForFabric=1.12;
        if(this.fabricSourcingType!=1){
          toleranceAmountForFabric=1.02;
        }
        let pastalSumAmount = sumAmount/Number(findOrderFabric.pastal);
        let amoutPastal=Number(pastalSumAmount)*Number(findOrderFabric.pastal);

        let amountPastalTolerance=amoutPastal*toleranceAmountForFabric;
        maxToleranceFabric=amountPastalTolerance* Number(findOrderFabric.currency_price);
        fabricCurrencyUnit=findOrderFabric.currency_unit_id;
      }
      
      if(allInstructionKeys.includes("sample.ready_purchase")){
        let models=_.cloneDeep(this.readyMadeDetailModel);
        let modelTotal=0;
        models.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          modelTotal=modelTotal+total;
        })
        allInstructionTotal.push({title:"sample.ready_purchase",total:modelTotal});
      }

      if(allInstructionKeys.includes("sample.extra_processes")){
        let models=_.cloneDeep(this.readyAdditionDetailModel);
        let modelTotal=0;
        models.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          modelTotal=modelTotal+total;
        })
        allInstructionTotal.push({title:"sample.extra_processes",total:modelTotal});
      }

  
      let data ={
          fabricCurrency:fabricCurrencyUnit,
          maxToleranceFabric:maxToleranceFabric,
          allInstructionTotal:allInstructionTotal,
          totalInstruction:totalModels
        };
        return data;
    },
    allInstructionTotalfabricSourcingTypeOne(){
      let allInstruction=[];
      let allInstructionKeys=[];
      let allInstructionTotal=[];
      let fabricCurrency=null;
      let maxToleranceFabric=0;
      let totalModels=0;
      let outSourcingTypes=_.cloneDeep(this.outSourcingTypes);
      Object.entries(outSourcingTypes).forEach(([key, sourcingType]) => {
        if(sourcingType.value.includes(Number(this.fabricProcessingType))){
          allInstruction.push(sourcingType);
          allInstructionKeys.push(key);
        }
      });

      let selectedOrderFabricId=+this.orderFabricId;
      let orderFabrics=_.cloneDeep(this.order.order_fabrics);
      let findOrderFabric=_.find(orderFabrics,{'id':selectedOrderFabricId})
      
      let fabricCurrencyUnit=null;
      let allCurrencyRate=this.options.currencyRateOptions;
      // maxsimum bu siparişe girilebilecek miktar bulunuyor
      if(findOrderFabric){
        let order_fabric_requirements=_.cloneDeep(this.order.order_fabric_requirements);
        // burada kumaş ihtiyaçlarının ekstra girişleri doğrultusunda yeni max değer bulunuyor ve ona göre max değer hesapanıyor
        let sumAmount=0;
        order_fabric_requirements.forEach((item)=>{
          if(item.order_fabric_id==findOrderFabric.id){
            sumAmount+=item.amount;
          }
        })
        let pastalSumAmount = sumAmount/Number(findOrderFabric.pastal);
        let amoutPastal=Number(pastalSumAmount)*Number(findOrderFabric.pastal);
        let amountPastalTolerance=amoutPastal*1.12;
        maxToleranceFabric=amountPastalTolerance* Number(findOrderFabric.currency_price);
        fabricCurrencyUnit=findOrderFabric.currency_unit_id;
      }
      
      if(allInstructionKeys.includes("sample.yarn")){
        let yarnModels=_.cloneDeep(this.yarnDetailModel);
        let yarnTotal=0;
        yarnModels.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          yarnTotal=yarnTotal+total;
        })
        allInstructionTotal.push({title:"sample.yarn",total:yarnTotal});
      }

      if(allInstructionKeys.includes("sample.knitting")){
        let knitDetailModel=_.cloneDeep(this.knitDetailModel);
        let knitTotal=0;
        knitDetailModel.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          knitTotal=knitTotal+total;
        })
        allInstructionTotal.push({title:"sample.knitting",total:knitTotal});
      }
    

      
      
      if(allInstructionKeys.includes("sample.raw_fabric")){
        let rawFabricDetailModel=_.cloneDeep(this.rawFabricDetailModel);
        let rawTotal=0;
        rawFabricDetailModel.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          rawTotal=rawTotal+total;
        })
        allInstructionTotal.push({title:"sample.raw_fabric",total:rawTotal});
      }

      if(allInstructionKeys.includes("sample.press")){//print
        let printDetailModel=_.cloneDeep(this.printDetailModel);
        let pressTotal=0;
        printDetailModel.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          pressTotal=pressTotal+total;
        })
        allInstructionTotal.push({title:"sample.press",total:pressTotal});
      }

      if(allInstructionKeys.includes("sample.paint")){
        let paintDetailModel=_.cloneDeep(this.paintDetailModel);
        let panintTotal=0;
        paintDetailModel.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          panintTotal=panintTotal+total;
        })
        allInstructionTotal.push({title:"sample.paint",total:panintTotal});
      }
     
      if(allInstructionKeys.includes("sample.extra_processes")){
        let additionDetailModel=_.cloneDeep(this.additionDetailModel);
        let extraTotal=0;
        additionDetailModel.forEach(model=>{
          let total=this.totalCalculateForModels(this.order,findOrderFabric,model,allCurrencyRate);
          totalModels=totalModels+total;
          extraTotal=extraTotal+total;
        })
        allInstructionTotal.push({title:"sample.extra_processes",total:extraTotal});
      }
  
      let data ={
          fabricCurrency:fabricCurrencyUnit,
          maxToleranceFabric:maxToleranceFabric,
          allInstructionTotal:allInstructionTotal,
          totalInstruction:totalModels
        };

        return data;
    },
    printTolerances() {
      let fabricRequests = this.tolerances;
      if (fabricRequests) {
        return {
          '1': fabricRequests.print_pigment_coefficient,
          '2': fabricRequests.print_reagent_coefficient,
          '3': fabricRequests.print_disperse_coefficient,
          '4': fabricRequests.print_devore_coefficient,
          '5': fabricRequests.additional_processing_coefficient,
        }
      }
      return null;
    },
    tolerances() {
      let fabricRequests = null;

      if (this.settings) {
        let settings = _.first(this.settings);
        if (settings) {
          fabricRequests = settings.fabric_request;
        }
      }
      return fabricRequests;
    },
    settings() {
      return this.$store.getters[_MODULE_USER + '/' + GENERAL_SETTING]
    },
    orderFabricOptions() {
      let items = this.order.order_fabrics;
      return this.convertArrayToObjectByKey(items, 'id', 'type');
    },
    orderSizeAndColorOptions() {
      let items = this.order.order_size_and_colors;
      return this.convertArrayToObjectByKey(items, 'id', 'full_name');
    },
    orderSizeAndColorNameOptions() {
      let items = this.order.order_size_and_colors;
      return this.convertArrayToObjectByKey(items, 'id', 'color_name');
    },
    currencyInstructionTypeId() {
      if (+this.fabricSourcingType === 2) return 5;
      return this.fabricProcessingType;
    },
    didChangeProcessingType() {
      if (+this.fabricSourcingType === 2 && +this.order.fabric_supply_type_id === 5) {
        return false;
      } else if (+this.fabricSourcingType === 1 && +this.fabricProcessingType === +this.order.fabric_supply_type_id) {
        return false;
      }
      return true
    },
    maxToleranceAmount() {
      let settings = _.first(this.settings);
      let fabricRequests;
      if (settings) {
        fabricRequests = settings.fabric_request;
      }
      const toleranceAmounts = this.printTolerances;

      if (+this.fabricSourcingType === 2) {
        return fabricRequests.ready_fabric_coefficient;
      } else if (+this.fabricProcessingType === 1) {
        return Number(fabricRequests.knit_coefficient) + Number(fabricRequests.paint_coefficient);
      } else if (+this.fabricProcessingType === 2) {
        return Number(toleranceAmounts[this.selectedPrintTypeId]) + Number(fabricRequests.knit_coefficient);
      } else if (+this.fabricProcessingType === 3) {
        return Number(fabricRequests.paint_coefficient);
      } else if (+this.fabricProcessingType === 4) {
        return toleranceAmounts[this.selectedPrintTypeId];
      } else {
        this.sweetAlertError(this.$t('order.error_occurred_with_fabric_wastage_rates'));
        return 0;
      }
    }
  },
  methods: {
    totalCalculateForModels(order,orderFabric,model,allCurrencyRate){
      let modelTotal=0;
      if(order && orderFabric && model && allCurrencyRate&&(model.currency_unit || model.currency_unit_id)){
        let fabricCurrencyUnit=orderFabric.currency_unit_id;
        let modelFindCurrencyUnit=null;
        let currentOption = _.find(this.options.currencyRawOptions,{'id':+model.currency_unit_id})
        if(model.currency_unit&&currentOption.code===model.currency_unit.code){
          modelFindCurrencyUnit=model.currency_unit.code
        }else{
          modelFindCurrencyUnit=this.options.currencyOptions[model.currency_unit_id];
        }
        let findCurrencyRate=_.find(allCurrencyRate,{"code":modelFindCurrencyUnit});
        let findCurrencyRateforTl=_.find(allCurrencyRate,{"code":fabricCurrencyUnit});
        let modelExchanegeRate=1;

        if((fabricCurrencyUnit=="TL"||fabricCurrencyUnit=="TRY")&&(modelFindCurrencyUnit=="TRY"||modelFindCurrencyUnit=="TL")){
          modelExchanegeRate=findCurrencyRateforTl.rate;
          modelTotal=Number(model.total_price)/modelExchanegeRate;
        } else if(fabricCurrencyUnit==modelFindCurrencyUnit){
          modelTotal=Number(model.total_price);
        }else{
          modelExchanegeRate=findCurrencyRate.rate/orderFabric.exchange_rate;
          modelTotal=Number(model.total_price)*Number(modelExchanegeRate);
        }
      }
      return modelTotal;
    },
    readyChangeSelectedSubSource(value){
      if (+value.id === 7 && this.readyMadeDetailModel[0].is_submitted) {

        this.readySelectedSubSourcingType = value.id;
      }

      if (+value.id === 8 && this.readyAdditionDetailModel[0].is_submitted) {
        this.readySelectedSubSourcingType = value.id;
      }
      this.readySelectedSubSourcingType = value.id;
    },
    changeSelectedSubSource(value) {
      if (+value.id === 2 && this.yarnDetailModel[0].is_submitted) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id === 3 && (+this.fabricProcessingType === 1 && this.knitDetailModel[0].is_submitted) ||
          (+this.fabricProcessingType === 3 && this.rawFabricDetailModel[0].is_submitted)) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id === 5 && (+this.fabricProcessingType === 2 && this.knitDetailModel[0].is_submitted) ||
          (+this.fabricProcessingType === 4 && this.rawFabricDetailModel[0].is_submitted)) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id === 6 && ((+this.fabricProcessingType === 1 || +this.fabricProcessingType === 3) && this.paintDetailModel[0].is_submitted) ||
          ((+this.fabricProcessingType === 2 || +this.fabricProcessingType === 4) && this.printDetailModel[0].is_submitted)) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id < +this.selectedSubSourcingType) {
        this.selectedSubSourcingType = value.id;
      }
      
    },
    onInstructionTypeChange(newId) {
      this.order.fabric_supply_type_id = newId;
      if (+newId === 1) {
        this.printDetailModel = _.cloneDeepWith(this.printDetailModelDefault);
        this.rawFabricDetailModel = _.cloneDeepWith(this.rawFabricDetailModelDefault);
        this.readyMadeDetailModel = _.cloneDeepWith(this.readyMadeDetailModelDefault);
      } else if (+newId === 2) {
        this.paintDetailModel = _.cloneDeepWith(this.paintDetailModelDefault);
        this.rawFabricDetailModel = _.cloneDeepWith(this.rawFabricDetailModelDefault);
        this.readyMadeDetailModel = _.cloneDeepWith(this.readyMadeDetailModelDefault);
      } else if (+newId === 3) {
        this.yarnDetailModel = _.cloneDeepWith(this.yarnDetailModelDefault);
        this.knitDetailModel = _.cloneDeepWith(this.knitDetailModelDefault);
        this.printDetailModel = _.cloneDeepWith(this.printDetailModelDefault);
        this.readyMadeDetailModel = _.cloneDeepWith(this.readyMadeDetailModelDefault);
      } else if (+newId === 4) {
        this.yarnDetailModel = _.cloneDeepWith(this.yarnDetailModelDefault);
        this.knitDetailModel = _.cloneDeepWith(this.knitDetailModelDefault);
        this.paintDetailModel = _.cloneDeepWith(this.paintDetailModelDefault);
        this.readyMadeDetailModel = _.cloneDeepWith(this.readyMadeDetailModelDefault);
      } else {
        this.yarnDetailModel = _.cloneDeepWith(this.yarnDetailModelDefault);
        this.knitDetailModel = _.cloneDeepWith(this.knitDetailModelDefault);
        this.paintDetailModel = _.cloneDeepWith(this.paintDetailModelDefault);
        this.printDetailModel = _.cloneDeepWith(this.printDetailModelDefault);
        this.rawFabricDetailModel = _.cloneDeepWith(this.rawFabricDetailModelDefault);
        this.additionDetailModel = _.cloneDeepWith(this.additionDetailModelDefault);
      }
    },
    onFabricAdditionValueChange(payload) {
      this.additionDetailModel = this.additionDetailModel.map(detail => {
        detail.fabric_addition_id = payload.newValue;
        return detail;
      })
    },
    changeFabricPrintTypeId(payload) {
      this.selectedPrintTypeId = payload;
    },
    getRequests() {
      if (!this.orderFabricId) return false;

      let fabricProcessingType = Number(this.fabricProcessingType) ?? null;

      if (+this.fabricSourcingType === 2) {
        fabricProcessingType = 5
      }

      if (!fabricProcessingType) return false;
      this.getIncomingFabricAmount();

      let self = this;
      let url = 'api/order/' + this.order.id + '/fabric-requests/' + fabricProcessingType + '/order-fabric/' + this.orderFabricId;
      this.sweetAlertLoading();
      this.$store.dispatch(GET_ITEMS, {
        url: url,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          let data = result.data;
          let tmpItems = [];
          let tmpRequests = [];

          self.yarnDetailModel = [{}];
          self.readyMadeDetailModel = [{}];
          self.knitDetailModel = [{}];
          self.paintDetailModel = [{}];
          self.additionDetailModel = [{}];
          self.printDetailModel = [{}];
          self.rawFabricDetailModel = [{}];

          if (fabricProcessingType === 1 || fabricProcessingType === 2) {
            tmpRequests = data.yarn_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.yarnDetailModelDefault));
            }

            self.yarnDetailModel = tmpItems;
          }

          if (fabricProcessingType === 1 || fabricProcessingType === 2) {
            // Getting knit requests
            tmpRequests = data.knit_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.knitDetailModelDefault));
            }

            self.knitDetailModel = tmpItems;
          }
          if (fabricProcessingType === 1 || fabricProcessingType === 3) {
            // Getting paint requests
            tmpRequests = data.paint_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.paintDetailModelDefault));
            }

            self.paintDetailModel = tmpItems;
          }

          if (fabricProcessingType === 2 || fabricProcessingType === 4) {
            // Getting print requests
            tmpRequests = data.print_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              self.selectedPrintTypeId = item.press_type_id;
              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.printDetailModelDefault));
            }

            self.printDetailModel = tmpItems;
          }

          if (fabricProcessingType === 3 || fabricProcessingType === 4) {
            // Getting print requests
            tmpRequests = data.raw_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.rawFabricDetailModelDefault));
            }

            self.rawFabricDetailModel = tmpItems;
          }

          if (fabricProcessingType === 5) {
            // Getting print requests
            tmpRequests = data.ready_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              
              tmpItems.push(_.cloneDeepWith(this.readyMadeDetailModelDefault));
            }

            
            self.readyMadeDetailModel = tmpItems;
          }
          if (fabricProcessingType === 5) {
            // Getting print requests
            tmpRequests = data.addition_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.readyAdditionDetailModelDefault));
            }
            self.readyAdditionDetailModel = tmpItems;
          }
          if (fabricProcessingType !== 5) {
            // Getting print requests
            tmpRequests = data.addition_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.additionDetailModelDefault));
            }

            self.additionDetailModel = tmpItems;
          }
        }
        this.sweetAlertClose();
      })
    },
    getIncomingFabricAmount() {
      if (!this.orderFabricId) return false;
      let self = this;
      this.sweetAlertLoading();
      let url = 'api/order/' + this.order.id + '/order-fabric/' + this.orderFabricId + '/amount';
      this.$store.dispatch(GET_ITEMS, {
        url: url,
        acceptPromise: true,
      }).then((result) => {
        this.sweetAlertClose();

        if (result.status) {
          self.netIncomingFabricAmount = result.data;

          // TODO burası düzeltilecek, seçilen kumaşın tüm renkleri için miktarlar geldiyse uyıar verilecek
          /*          let fabricItem = self.order.order_fabric_requirements.find(x => parseInt(x.order_fabric_id) === parseInt(self.orderFabricId));

                    if (self.netIncomingFabricAmount == fabricItem.amount) {
                      self.sweetAlertClose();
                      setTimeout(() => {
                        self.sweetAlertError("general.fabric_requests_completed");
                      }, 100)
                    }*/
        }
      })
    }
  },
  mounted() {

    this.yarnDetailModel = [_.cloneDeepWith(this.yarnDetailModelDefault)];
    this.knitDetailModel = [_.cloneDeepWith(this.knitDetailModelDefault)];
    this.printDetailModel = [_.cloneDeepWith(this.printDetailModelDefault)];
    this.paintDetailModel = [_.cloneDeepWith(this.paintDetailModelDefault)];
    this.rawFabricDetailModel = [_.cloneDeepWith(this.rawFabricDetailModelDefault)];
    this.additionDetailModel = [_.cloneDeepWith(this.additionDetailModelDefault)];
    this.readyMadeDetailModel = [_.cloneDeepWith(this.readyMadeDetailModelDefault)];
    this.readyAdditionDetailModel = [_.cloneDeepWith(this.readyAdditionDetailModelDefault)];

    if (this.order && 'fabric_supply_type_id' in this.order) {
      this.fabricSourcingType = +this.order.fabric_supply_type_id === 5 ? 2 : 1;
      this.fabricProcessingType = +this.order.fabric_supply_type_id === 5 ? null : +this.order.fabric_supply_type_id;
      const yarnRequest = {
        name: 'yarnDetailModel',
        model: this.yarnDetailModel,
        dates: ['flow_date', 'end_date'],
        relationships: [],
      };
      const knitRequest = {
        name: 'knitDetailModel',
        model: this.knitDetailModel,
        dates: ['flow_date', 'end_date'],
        relationships: [],
      };
      const paintRequest = {
        name: 'paintDetailModel',
        model: this.paintDetailModel,
        dates: ['raw_flow_date', 'raw_end_date', 'painted_end_date', 'painted_flow_date'],
        relationships: [
          {
            name: 'fabric_processes',
            key: 'fabric_process_id',
            to: 'fabric_process_ids',
          }
        ]
      };
      const additionRequest = {
        name: 'additionDetailModel',
        model: this.additionDetailModel,
        dates: ['painted_end_date', 'painted_flow_date'],
        relationships: [
          {
            name: 'fabric_processes',
            key: 'fabric_process_id',
            to: 'fabric_process_ids',
          }
        ],
      };
      const rawFabricRequest = {
        name: 'rawFabricDetailModel',
        model: this.rawFabricDetailModel,
        dates: ['flow_date', 'end_date'],
        relationships: [],
      };
      const printRequest = {
        name: 'printDetailModel',
        model: this.printDetailModel,
        dates: ['raw_flow_date', 'raw_end_date', 'painted_end_date', 'painted_flow_date'],
        relationships: [
          {
            name: 'fabric_processes',
            key: 'fabric_process_id',
            to: 'fabric_process_ids',
          }
        ]
      };
      const readyMadeRequest = {
        name: 'readyMadeDetailModel',
        model: this.readyMadeDetailModel,
        dates: [],
        relationships: [
          {
            name: 'fabric_processes',
            key: 'fabric_process_id',
            to: 'fabric_process_ids',
          }
        ],
      };
      const urlHolder = {
        1: {
          'yarn-request': yarnRequest,
          'knit-request': knitRequest,
          'paint-request': paintRequest,
          'addition-request': additionRequest,
        },
        2: {
          'yarn-request': yarnRequest,
          'knit-request': knitRequest,
          'print-request': printRequest,
          'addition-request': additionRequest,
        },
        3: {
          'raw-fabric-request': rawFabricRequest,
          'paint-request': paintRequest,
          'addition-request': additionRequest,
        },
        4: {
          'raw-fabric-request': rawFabricRequest,
          'print-request': printRequest,
          'addition-request': additionRequest,
        },
        5: {
          'ready-made-request': readyMadeRequest,
        }
      }, promises = [], self = this;
      this.isDownloading = true;
      this.sweetAlertLoading();
      for (let key in urlHolder[+this.order.fabric_supply_type_id]) {
        promises.push(this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: `api/order/${this.orderId}/${key}`
        }));
      }
      Promise.all(promises).then(responses => {
        let allGood = true;
        for (let [index, [key, value]] of Object.entries(urlHolder[+self.order.fabric_supply_type_id]).entries()) {
          if (responses[index].status) {
            if (responses[index].data.length > 0) {
              value.model.splice(0, value.model.length);
              value.model.push(...responses[index].data.map((model, i) => {
                model.is_submitted = true;
                model.dispatch_location_ids = model.dispatch_locations.map((location) => {
                  location.id = location.dispatch_location_id.toString();
                  return location.dispatch_location_id.toString();
                });
                model.selectedProductProcesses = model.product_processes.map(process => {
                  process.id = process.product_process_id.toString();
                  return process.product_process_id.toString();
                });
                value.relationships.forEach(relationship => {
                  model[relationship.to] = model[relationship.name].map((v) => {
                    v.id = self.getValueByDottedKey(v, relationship.key).toString();
                    return self.getValueByDottedKey(v, relationship.key).toString();
                  });
                });
                value.dates.forEach((date, j) => {
                  model[date] = moment(model[date], LARAVEL_DATE_FORMAT);
                });

                return model
              }))
            }
          } else {
            allGood = false;
            break;
          }
        }
        if (!allGood) {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
        self.isDownloading = false;
        self.sweetAlertClose();
      }).catch(error => {
        console.error(error);
        self.isDownloading = false;
        self.sweetAlertClose();
      })
    }
    if(this.$route.query.qr_type=="order-ready-request"){
      this.fabricSourcingType=2;
      this.orderFabricId=this.$route.query.data.order_fabric_id;
      this.getRequests();
    }

    if(this.$route.query.qr_type=="raw-fabric"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.order_fabric_id
      this.selectedSubSourcingType=4;
      this.getRequests();
    }
    if(this.$route.query.qr_type=="order-print"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.order_fabric_id
      this.selectedSubSourcingType=5;
      this.getRequests();
    }

    if(this.$route.query.qr_type=="order-paint"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.order_fabric_id
      this.selectedSubSourcingType=3;
      this.getRequests();
    }

    if(this.$route.query.qr_type=="knit-request"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.order_fabric_id
      this.selectedSubSourcingType=2;
      this.getRequests();
    }
    if(this.$route.query.qr_type=="yarn-request"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.order_fabric_id
      this.selectedSubSourcingType=1;
      this.getRequests();
    }
    if(this.$route.query.qr_type=="addition-request"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.order_fabric_id
      this.selectedSubSourcingType=6;
      this.getRequests();
    }
  },
  watch: {
    fabricProcessingType(newValue, oldValue) {
      let isIncluded = false, firstAvailable = null;

      for (let key in this.outSourcingTypes) {
        const current = this.outSourcingTypes[key]
        if (firstAvailable === null && current.value.includes(+newValue)) {
          firstAvailable = current.id;
        }
        if (current.value.includes(+newValue) && +this.selectedSubSourcingType === current.id) {
          isIncluded = true;
          break;
        }
      }
      if (!isIncluded) {
        this.selectedSubSourcingType = firstAvailable;
      }
    },
    fabricSourcingType(newValue){
      if (newValue==2){
        if(this.readySelectedSubSourcingType == null){
          this.readySelectedSubSourcingType=+7;
          this.fabricProcessingType=5;
        }
        
      }
    }
  }
}
</script>

<style scoped>

</style>
